import React from "react"

const Objections = () => {
  return (
    <div className="berserkseo-objections">
      <div className="berserkseo-objections__col align-self-c">
        <div className="berserkseo-objections__h">
          Most businesses know that SEO can be a game-changer for their
          business.
        </div>
        <br />
        <div className="berserkseo-objections__h">
          But why do businesses end up giving up on SEO?
        </div>
        <br />
        <div className="berserkseo-objections__h">
          Here are the three most common reasons:
        </div>
        <br />
        <div className="berserkseo-objections__h highlight">
          {/* <span>1.</span>  */}
          SEO is too difficult and time consuming.
        </div>
        <br />
        <div className="berserkseo-objections__h highlight">
          {/* <span>2.</span> */}
          We don’t have thousands of dollars to invest in a marketing agency.
        </div>
        <br />
        <div className="berserkseo-objections__h highlight">
          {/* <span>3.</span>  */}
          We’ve been burned by a marketing agency before.
        </div>
      </div>
      <div
        style={{ alignSelf: "center" }}
        className="berserkseo-objections__col berserkseo-objections__hook"
      >
        <div className="berserkseo-objections__h">
          Let’s see how <b>berserkseo</b> addresses each of these problems.
        </div>
      </div>
      <div className="berserkseo-objections__col">
        <div className="berserkseo-objections__h">
          SEO is too difficult and time consuming.
        </div>

        <div className="berserkseo-objections__p">
          You set the number of hours you have available, and our platform will
          deliver the best tailored step-by-step recommendations that will tell
          you exactly what to do in order to climb Google within that time
          frame. A little here and there goes a long way, and <b>berserkseo</b>{" "}
          holds your hand throughout the entire process.{" "}
        </div>
        <br />
        <div className="berserkseo-objections__p">
          A neat side effect of this is that you’ll become an SEO expert
          yourself.{" "}
        </div>
      </div>
      <div className="berserkseo-objections__col">
        <div className="berserkseo-objections__h">
          We don’t have thousands of dollars to invest in a marketing agency.
        </div>

        <div className="berserkseo-objections__p">
          Thousands of dollars a month can certainly break the bank. But what
          about 250 dollars a month? Actually, we can do better. During this
          launch we’re offering a{" "}
          <span
            className="highlight-span"
            style={{ textDecoration: "underline" }}
          >
            40% lifetime subscription for 150 dollars each month.
          </span>{" "}
          How does that sound? Oh, and cancel anytime.{" "}
        </div>
      </div>
      <div className="berserkseo-objections__col">
        <div className="berserkseo-objections__h">
          We’ve been burned by a marketing agency before.
        </div>

        <div className="berserkseo-objections__p">
          <b>berserkseo</b> removes the middleman and equips you with everything
          you need to command SEO in-house.{" "}
        </div>
        <br />
        <div className="berserkseo-objections__p">
          That being said, should you ever need assistance with any of your
          action items, we can do that for you at an affordable and transparent
          pricing model.{" "}
        </div>
      </div>
    </div>
  )
}

export default Objections
