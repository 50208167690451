import React, { useState } from "react"

const Features = () => {
  const [feature, setFeature] = useState("strategy")
  return (
    <div className="berserkseo-features">
      <div className="berserkseo-features__header">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 340.69 61.17"
        >
          <path
            fill="#fff"
            className="cls-1"
            d="M319.49 368.33v9.94h19.2v12.83h-19.2V415h-14.95v-60.35h35v13.68zM385.42 398.58h-30c1.78 3.56 5.6 5.35 10.7 5.35a16.79 16.79 0 0010-2.89l6.45 6.88c-3.57 4.5-9.77 7.9-18.09 7.9-12.41 0-23.45-8.32-23.45-22.85 0-15 11.21-23 22.68-23 11.29-.03 24.77 7.7 21.71 28.61zm-30.5-10.37h17.58c-1-4.16-4.84-6-8.58-6a9.22 9.22 0 00-9 6zM389.07 392.88c0-15.46 11.05-22.94 20.9-22.94 7.73 0 11.9 4.25 12.24 5h.25v-4.17h14.7V415h-14.7v-4.16h-.25c-.34.76-4.51 5-12.24 5-9.85-.02-20.9-7.49-20.9-22.96zm33.73 0c0-6.28-4.42-9.94-9.69-9.94-5.09 0-9.6 3.74-9.6 9.94s4.51 9.94 9.6 9.94c5.27 0 9.69-3.65 9.69-9.94zM473.44 401.89V413c-1.7 1.87-5.7 2.46-9 2.46-8.84 0-15.21-4.25-15.21-15.29v-17.82h-6.71v-11.56h6.71v-11.3h14.7v11.3h9.35v11.56h-9.35V398c0 2 1.19 4.59 5.35 4.59a14.05 14.05 0 004.16-.7zM522.71 370.79V415H508v-5.18c-2.55 3.57-6.72 6-12.66 6-8.84 0-16.32-5.52-16.32-19v-26h14.7v23.45c0 4.59 2.21 8.58 7.22 8.58 4.85 0 7.06-4 7.06-8.58v-23.48zM561.71 370.37v14a13.17 13.17 0 00-3.23-.34c-8.83 0-13.59 5.77-13.59 13.76V415h-14.7v-44.21h14.7v12.07c1.44-7.31 5.69-12.92 13.51-12.92a11.81 11.81 0 013.31.43zM608 398.58h-30c1.79 3.56 5.61 5.35 10.71 5.35a16.74 16.74 0 0010-2.89l6.46 6.88c-3.57 4.5-9.77 7.9-18.1 7.9-12.4 0-23.44-8.32-23.44-22.85 0-15 11.21-23 22.68-23 11.25-.03 24.76 7.7 21.69 28.61zm-30.5-10.37h17.6c-1-4.16-4.84-6-8.58-6a9.23 9.23 0 00-9.01 6zM610 403.08l11.55-3.15c.6 1.53 1.62 4.51 5.53 4.51 2 0 3.73-.94 3.73-2.81 0-.93-.51-2.12-2.8-2.8l-6.46-2.38c-7.3-2.55-10.45-7.39-10.45-13.08 0-8.5 7.73-13.43 16.66-13.43 8.66 0 14.95 4.93 16.39 12.32l-11.47 2.81c-.93-3.4-3.06-3.91-4.33-3.91-2.29 0-3.06 1.53-3.06 2.46s.6 2.21 3.15 2.89l6 2.21c4.25 1.36 10.79 4.67 10.79 12.91 0 8.92-7.65 14.19-17.93 14.19-9.09 0-15.97-4.08-17.3-12.74z"
            transform="translate(-304.54 -354.65)"
          />
        </svg>
      </div>
      <div className="berserkseo-features__feature-container">
        <div className="berserkseo-features__feature-list-wrapper">
          <div className="berserkseo-features__feature-list">
            <div
              style={{
                backgroundColor: `${
                  feature === "strategy" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("strategy")}
              className="berserkseo-features__feature-list-item"
            >
              Strategy
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "call" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("call")}
              className="berserkseo-features__feature-list-item"
            >
              SEO Call
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "actions" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("actions")}
              className="berserkseo-features__feature-list-item"
            >
              Action List
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "academy" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("academy")}
              className="berserkseo-features__feature-list-item"
            >
              SEO Academy
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "local" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("local")}
              className="berserkseo-features__feature-list-item"
            >
              Local SEO
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "reporting" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("reporting")}
              className="berserkseo-features__feature-list-item"
            >
              Reporting
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "backlinks" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("backlinks")}
              className="berserkseo-features__feature-list-item"
            >
              Backlinks
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "content" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("content")}
              className="berserkseo-features__feature-list-item"
            >
              Content
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "competition" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("competition")}
              className="berserkseo-features__feature-list-item"
            >
              Competition
            </div>
            <div
              style={{
                backgroundColor: `${
                  feature === "dfy" ? "rgb(255, 255, 255, .25)" : ""
                }`,
              }}
              onClick={() => setFeature("dfy")}
              className="berserkseo-features__feature-list-item"
            >
              Done for you
            </div>
          </div>
        </div>
        <div className="berserkseo-features__feature-description-container">
          {feature === "strategy" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Strategy
              </div>
              <div className="berserkseo-features__feature-description">
                Full access to <b>berserkseo's</b> unique and easy-to-use SEO
                strategy builder.
                <br />
                <br />
                This includes keyword research from up to 100 topics every
                month, and lets you choose 50 keywords that we will help you to
                optimize on your website and track ongoing.
                <br />
                <br />
                Once complete, send it to us for review for extra peace of mind.
                {/* Our team
                can even complete this for you, for extra peace of mind. */}
              </div>
            </>
          )}
          {feature === "call" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                SEO Call
              </div>
              <div className="berserkseo-features__feature-description">
                Each plan includes a 30-minute SEO strategy call with one of the{" "}
                <b>berserkseo</b> team.
                <br />
                <br />
                We’ll run through the SEO strategy you’ve built, and ensure
                you’re ready to use the platform.
                <br />
                <br />
                With over 16 years of combined experience in SEO, we’ll give you
                the confidence to know your SEO is on the right track!
              </div>
            </>
          )}
          {feature === "actions" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Actions List
              </div>
              <div className="berserkseo-features__feature-description">
                <b>berserkseo's</b> Action Engine will give you a list of
                tailored stream of tasks to complete on your website that will
                action the SEO strategy you’ve built.
                <br />
                <br />
                All tasks are accompanied by easy-to-follow & straightforward
                instructions that anyone can use.{" "}
              </div>
            </>
          )}
          {feature === "academy" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                SEO Academy
              </div>
              <div className="berserkseo-features__feature-description">
                The <b>berserkseo</b> academy is your one-stop-shop to learn
                SEO. It’s a video course with quizzes and actions to take you
                from SEO beginner to SEO pro.
                <br />
                <br />
                From understanding the foundations of technical SEO, to how to
                audit and build backlinks, the academy will give you the
                knowledge you need.
              </div>
            </>
          )}
          {feature === "local" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Local SEO
              </div>
              <div className="berserkseo-features__feature-description">
                <b>berserkseo</b> will help you to optimize your Google My
                Business listing for the top positions in the Map listings!
                <br />
                <br />
                We connect straight to Google My Business so all the
                optimizations can be done from directly within <b>berserkseo</b>
                .
              </div>
            </>
          )}

          {feature === "backlinks" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Backlinks
              </div>
              <div className="berserkseo-features__feature-description">
                We track yours and your competitors’ backlinks every month.
                <br />
                <br />
                We highlight total, newest and recently lost backlinks, and
                backlink opportunities based on your competitors link profiles.
                <br />
                <br />
                We get this data from Majestic – “The planet’s largest Link
                Index database”.
              </div>
            </>
          )}
          {feature === "competition" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Competition
              </div>
              <div className="berserkseo-features__feature-description">
                Track up to 3 different competitor websites every month.
                <br />
                <br />
                We’ll show you what traffic they get, where it comes from, their
                top SEO keywords and more.
                <br />
                <br />
                We also use their data to help with content ideas too.
              </div>
            </>
          )}
          {feature === "content" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Content
              </div>
              <div className="berserkseo-features__feature-description">
                Every month you can build a new content marketing strategy from
                3 new topics.
                <br />
                <br />
                Simply add a topic and we pull back content ideas from Google,
                Youtube, Twitter and your competitors.
                <br />
                <br />
                Create content around what people are actually searching for.
              </div>
            </>
          )}
          {feature === "reporting" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Reporting
              </div>
              <div className="berserkseo-features__feature-description">
                <b>berserkseo</b> not only helps you build and implement your
                SEO strategy, but it also tracks performance so you can see how
                the changes you’ve made are helping you reach the top of Google.
                <br />
                <br />
                We’ll track keywords, traffic, backlinks and competition.
              </div>
            </>
          )}
          {feature === "dfy" && (
            <>
              <div className="berserkseo-features__feature-description-header">
                Done for you
              </div>
              <div className="berserkseo-features__feature-description">
                Not sure if you’ll always have time to complete your SEO actions
                that <b>berserkseo</b> recommends?
                <br />
                <br />
                Then make use of the <b>berserkseo</b> team! Our team can
                complete any actions you need us to.
                <br />
                <br />
                No contracts; simply select which actions you want us to
                complete and we’ll do them for you!
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Features
