import "../styles/berserkseo.scss"
import React, { useState, useEffect } from "react"
import Header from "../components/Header"
import Intro from "../components/BerserkSEO/Intro"
import Objections from "../components/BerserkSEO/Objections"
import Features from "../components/BerserkSEO/Features"
import Price from "../components/BerserkSEO/Price"
import Footer from "../components/Footer"
import GoTrue from "gotrue-js"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"
import firebase from "gatsby-plugin-firebase"
// import { useAuth } from "../lib/firebase/firebaseCentral"
import { Helmet } from "react-helmet"
// import Steps from "../components/BerserkSEO/Steps"
export default function BerserkSEO() {
  const [identity, setIdentity] = useState(null)
  const [profile, setProfile] = useState(null)
  // const { profile } = useAuth()
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--scrollbar-width",
      window.innerWidth - document.documentElement.clientWidth + "px"
    )
  }, [])

  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })

    setIdentity(auth)
  }, [])

  useEffect(() => {
    if (identity?.currentUser()?.email) {
      if (
        identity &&
        identity.currentUser &&
        identity.currentUser() &&
        identity.currentUser().email
      ) {
        const e = identity.currentUser().email
        // console.log("e", e);
        getUser(e)
      }

      async function getUser(e) {
        // console.log("e", e);
        const userRef = firebase
          .firestore()
          .collection("users")
          // .doc(identity.currentUser().email);
          .doc(e)
        const userDoc = await userRef.get()
        const userData = await userDoc.data()
        setProfile(userData)
      }
    }
  }, [identity])
  // console.log("profile", profile);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Berserk SEO</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header />
      <div className="berserkseo">
        <Intro />
        <Objections />
        <Features />
        <Price profile={profile || null} />
        <Footer />
        {/* <Steps /> */}
      </div>
    </>
  )
}
